import React, { useEffect, useState } from 'react'
import Index from '../../../container/Index';
import PagesIndex from '../../../container/PagesIndex';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  p: 4,
};


export default function Header() {


  // const downloadFile = (e) => {
  //   e.preventDefault();
  //   fetch("../../../assets/images/pdf/aurum-whitepaper.pdf").then((response) => {
  //     response.blob().then((blob) => {
  //       const fileURL = window.URL.createObjectURL(blob);
  //       let alink = document.createElement("a");
  //       alink.href = fileURL;
  //       alink.download = "aurum-whitepaper.pdf";
  //       alink.click();
  //     });
  //   });
  // };

  const [isActive, setIsActive] = useState(false);

  const handleClick = event => {
    // 👇️ toggle isActive state on click
    setIsActive(current => !current);
    document.body.classList[isActive ? "remove" : "add"]('body-overflow');
  };
  useEffect(() => {
    window.addEventListener('scroll', () => {
      if (window.scrollY === 0) {
        document.getElementById("header-main").classList.remove("sticky-header");
        document.getElementById("top-scroll-box").classList.remove("top-scroll-box-show");
      } else {
        document.getElementById("header-main").classList.add("sticky-header");
        document.getElementById("top-scroll-box").classList.add("top-scroll-box-show");
      }
    });
  }, [])
  const handleClickMenuItem = (id) => {
    var element = document.getElementById(id)
    element.scrollIntoView({ behavior: "smooth", block: "start" });
    handleClick();
  }


  // add user modal
  const [openComingSoon, setOpenComingSoon] = React.useState(false);
  const handleOpenComingSoon = () => setOpenComingSoon(true);
  const handleCloseComingSoon = () => setOpenComingSoon(false);

  return (
    <>
      <Index.Box className="header-main">
        <Index.Box className="container">
          <Index.Box className="header-row" id="header-main">
            <Index.Box className="header-cust-col">
              <Index.Box className="header-logo-box">
                <img src={PagesIndex.Svg.logo} className='header-logo' alt='logo' />
              </Index.Box>
            </Index.Box>
            <Index.Box className="header-cust-col">
              <Index.Box className={`header-nav-main ${isActive ? "mobilemenu-active" : ""}`}>
                <Index.Box className="mobile-logo-main">
                  <Index.Box className="mobile-logo-flex">
                    <Index.Box className="mobile-logo-box">
                      <img src={PagesIndex.Svg.logo} className='mobile-logo' alt='logo' />
                      <Index.Typography className='mobile-logo-text'>Aurum</Index.Typography>
                    </Index.Box>
                    <Index.Button className="mobile-menu-btn" onClick={handleClick}>
                      <img src={PagesIndex.Svg.closeIcon} className='mobile-menu-close-icon' alt='Close' />
                    </Index.Button>
                  </Index.Box>
                </Index.Box>
                <Index.List className='header-nav-ul'>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("about-us-main")
                    }}>About Us</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("tokenomics-main")
                    }}>Tokenomics</Index.Link>
                  </Index.ListItem>
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("roadmap-main")
                    }}>Roadmap</Index.Link>
                  </Index.ListItem>
                  {/* <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("team-main")
                    }}>Our Team</Index.Link>
                  </Index.ListItem> */}
                  <Index.ListItem className='header-nav-li'>
                    <Index.Link className='header-nav-link' onClick={() => {
                      handleClickMenuItem("faq-main")
                    }}>FAQs</Index.Link>
                  </Index.ListItem>
                </Index.List>
              </Index.Box>
            </Index.Box>
            <Index.Box className="header-cust-col">
              <Index.Box className="header-right-main border-btn-main">
                <a className='border-btn header-border-btn' target='_blank' href={PagesIndex.Pdf.whitePaperPdf}>Whitepaper</a>
                <PagesIndex.PrimaryButton btnLabel="Buy Now" onClick={handleOpenComingSoon} className='primary-btn' />
                <Index.Button className='mobile-menu-btn' onClick={handleClick}>
                  <img src={PagesIndex.Svg.mobilemenuIcon} className='mobile-menu-icon' />
                </Index.Button>
              </Index.Box>
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Box>

      <Index.Modal
        open={openComingSoon}
        onClose={handleCloseComingSoon}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
        className='user-modal'
      >
        <Index.Box sx={style} className="user-modal-inner coming-modal-inner">
          <Index.Box className="user-modal-header">
            <Index.Typography id="modal-modal-title" className='user-modal-title' variant="h6" component="h2"></Index.Typography>
            <Index.Button className='user-close-btn' onClick={handleCloseComingSoon}><span><img src={PagesIndex.Svg.closeIcon} className="user-modal-close-icon" alt='Close' /></span></Index.Button>
          </Index.Box>
          <Index.Box className="user-modal-body">
            <Index.Box className="user-modal-hgt-scroll cus-scrollbar">
            <Index.Box className="coming-soon-logo-box">
                <img src={PagesIndex.Svg.logo} className='coming-soon-logo' alt='logo' />
              </Index.Box>
                <Index.Typography className='coming-soon-text'>
                  Coming Soon
                </Index.Typography>
              {/* <img src={PagesIndex.Gif.comingsoon} className="comin-modal-img" alt="coming soon"/> */}
            </Index.Box>
          </Index.Box>
        </Index.Box>
      </Index.Modal>
    </>
  )
}
