import React from 'react'
import Index from '../../../../container/Index'
import PagesIndex from '../../../../container/PagesIndex'


export default function Faq() {
      return (
            <>
                  <Index.Box className="faq-sec">
                        <Index.Box className="container">
                              <Index.Box className="faq-flex">
                                    <Index.Box className="faq-left-main">
                                          <Index.Typography className="faq-heading">FAQs</Index.Typography>
                                          <Index.Typography className='faq-sub-title'>Need help? Our FAQ has the answers you need.
                                          </Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="faq-right-main">
                                          <Index.Box className="accrodian-main">
                                                <Index.Accordion defaultExpanded className='accordian'>
                                                      <Index.AccordionSummary
                                                            expandIcon={<Index.ExpandMoreIcon />}
                                                            aria-controls="pane1-content"
                                                            id="panel1-header"
                                                      >
                                                            <Index.Typography className='accordian-title'>
                                                            What is Aurum?
                                                            </Index.Typography>
                                                      </Index.AccordionSummary>
                                                      <Index.AccordionDetails className='accordian-details'>
                                                      <Index.Typography className='accodian-details-para'>
                                                      Developed on the Solana blockchain, Aurum is a decentralized trade token intended to give customers anywhere a smooth and safe trading experience.
                                                            </Index.Typography>
                                                      </Index.AccordionDetails>
                                                </Index.Accordion>
                                                <Index.Accordion className='accordian'>
                                                      <Index.AccordionSummary
                                                            expandIcon={<Index.ExpandMoreIcon />}
                                                            aria-controls="panel2-content"
                                                            id="panel2-header"
                                                      >
                                                            <Index.Typography className='accordian-title'>
                                                            How can I buy Aurum tokens?
                                                            </Index.Typography>
                                                      </Index.AccordionSummary>
                                                      <Index.AccordionDetails className='accordian-details'>
                                                            <Index.Typography className='accodian-details-para'>
                                                            You can buy Aurum tokens through various decentralized exchanges (DEXs) where they are listed. Check our website for the latest updates on available platforms.
                                                            </Index.Typography>
                                                      </Index.AccordionDetails>
                                                </Index.Accordion>
                                                <Index.Accordion className='accordian'>
                                                      <Index.AccordionSummary
                                                            expandIcon={<Index.ExpandMoreIcon />}
                                                            aria-controls="panel3-content"
                                                            id="panel3-header"
                                                      >
                                                            <Index.Typography className='accordian-title'>
                                                            What makes Aurum different from other tokens?
                                                            </Index.Typography>
                                                      </Index.AccordionSummary>
                                                      <Index.AccordionDetails className='accordian-details'>
                                                            <Index.Typography className='accodian-details-para'>
                                                            Aurum leverages the characteristics of the Solana blockchain to be a high-performance trading token with quick transaction times and cheap fees.
                                                            </Index.Typography>
                                                      </Index.AccordionDetails>
                                                </Index.Accordion>
                                                <Index.Accordion className='accordian'>
                                                      <Index.AccordionSummary
                                                            expandIcon={<Index.ExpandMoreIcon />}
                                                            aria-controls="panel4-content"
                                                            id="panel4-header"
                                                      >
                                                            <Index.Typography className='accordian-title'>
                                                            Is there a maximum supply of Aurum tokens?
                                                            </Index.Typography>
                                                      </Index.AccordionSummary>
                                                      <Index.AccordionDetails className='accordian-details'>
                                                            <Index.Typography className='accodian-details-para'>
                                                            Yes, the total supply of Aurum tokens is capped at a specific amount to ensure scarcity and value stability. You can find detailed information in our white paper.
                                                            </Index.Typography>
                                                      </Index.AccordionDetails>
                                                </Index.Accordion>
                                                <Index.Accordion className='accordian'>
                                                      <Index.AccordionSummary
                                                            expandIcon={<Index.ExpandMoreIcon />}
                                                            aria-controls="panel5-content"
                                                            id="panel5-header"
                                                      >
                                                            <Index.Typography className='accordian-title'>
                                                            Where can I store my Aurum tokens?
                                                            </Index.Typography>
                                                      </Index.AccordionSummary>
                                                      <Index.AccordionDetails className='accordian-details'>
                                                            <Index.Typography className='accodian-details-para'>
                                                            Aurum tokens can be stored in any Solana-compatible wallet, such as Phantom or Sollet. Ensure your wallet supports SPL tokens.
                                                            </Index.Typography>
                                                      </Index.AccordionDetails>
                                                </Index.Accordion>
                                                <Index.Accordion className='accordian'>
                                                      <Index.AccordionSummary
                                                            expandIcon={<Index.ExpandMoreIcon />}
                                                            aria-controls="panel6-content"
                                                            id="panel6-header"
                                                      >
                                                            <Index.Typography className='accordian-title'>
                                                            How can I monitor the market performance of Aurum?
                                                            </Index.Typography>
                                                      </Index.AccordionSummary>
                                                      <Index.AccordionDetails className='accordian-details'>
                                                            <Index.Typography className='accodian-details-para'>
                                                            Sites like CoinMarketCap and other decentralized exchanges (DEXs) that list Aurum allow you to monitor the cryptocurrency's success in the market.
                                                            </Index.Typography>
                                                      </Index.AccordionDetails>
                                                </Index.Accordion>
                                                <Index.Accordion className='accordian'>
                                                      <Index.AccordionSummary
                                                            expandIcon={<Index.ExpandMoreIcon />}
                                                            aria-controls="panel6-content"
                                                            id="panel6-header"
                                                      >
                                                            <Index.Typography className='accordian-title'>
                                                            Are there any fees associated with trading Aurum?
                                                            </Index.Typography>
                                                      </Index.AccordionSummary>
                                                      <Index.AccordionDetails className='accordian-details'>
                                                            <Index.Typography className='accodian-details-para'>
                                                            Trading Aurum on decentralized exchanges incurs minimal transaction fees, thanks to the efficiency of the Solana blockchain. Specific fee details can be found on the respective exchange platforms.
                                                            </Index.Typography>
                                                      </Index.AccordionDetails>
                                                </Index.Accordion>
                                                <Index.Accordion className='accordian'>
                                                      <Index.AccordionSummary
                                                            expandIcon={<Index.ExpandMoreIcon />}
                                                            aria-controls="panel6-content"
                                                            id="panel6-header"
                                                      >
                                                            <Index.Typography className='accordian-title'>
                                                            How can I keep up with the advancements of Aurum?
                                                            </Index.Typography>
                                                      </Index.AccordionSummary>
                                                      <Index.AccordionDetails className='accordian-details'>
                                                            <Index.Typography className='accodian-details-para'>
                                                            Get up-to-date news, updates, and market trends about Aurum by subscribing to our newsletter and following us on social media. To learn more, go to our website.
                                                            </Index.Typography>
                                                      </Index.AccordionDetails>
                                                </Index.Accordion>
                                          </Index.Box>
                                    </Index.Box>
                              </Index.Box>
                              <Index.Box className="faq-coin-img-main">
                                    <img src={PagesIndex.Svg.faqCoin} className='faq-coin-img' alt='Coin'/>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
