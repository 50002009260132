
import Svg from '../assets/Svg'
import Png from "../assets/Png";
import Gif from '../assets/Gif';
import PrimaryButton from '../component/common/Button/PrimaryButton';
import BorderButton from '../component/common/Button/BorderButton';
import HeroSection from "../component/user/pages/herosection/HeroSection";
import Header from "../component/user/defaulLayout/Header";
import Footer from "../component/user/defaulLayout/Footer";
import AboutUs from '../component/user/pages/aboutUs/AboutUs';
import Tokenomics from '../component/user/pages/tokenomics/Tokenomics';
import Team from '../component/user/pages/team/Team';
import Roadmap from '../component/user/pages/roadmap/Roadmap';
import Faq from '../component/user/pages/faq/Faq';
import { validateUser } from '../validation/validation';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import Pdf from '../assets/Pdf';


export default {
      Svg,
      Formik,
      Form,
      Field,
      ErrorMessage,
      Png,
      Header,
      Footer,
      PrimaryButton,
      BorderButton,
      HeroSection,
      AboutUs,
      Tokenomics,
      Team,
      Roadmap,
      Faq,
      validateUser,
      Pdf,
      Gif,
};
