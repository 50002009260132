import React from 'react'
import Index from '../../../../container/Index'
import PagesIndex from '../../../../container/PagesIndex'

export default function Team() {
      return (
            <>
                  <Index.Box className="team-sec">
                        <Index.Box className="container">
                              {/* <Index.Typography className='team-heading'>OUR TEAM</Index.Typography> */}
                              <Index.Typography className='team-heading'>Our Growth Experts</Index.Typography>
                              <Index.Typography className='team-para'>Our experts are passionate about helping you achieve your financial goals. With unparalleled dedication, they're committed to going the extra mile.
                              </Index.Typography>
                              <Index.Box className='team-row'>
                                    <Index.Box className="team-box">
                                          <Index.Box className="team-img-box">
                                                <img src={PagesIndex.Svg.team1} alt="team" className='team-img' />
                                          </Index.Box>
                                          <Index.Typography className='team-member-name'>Drake Smith</Index.Typography>
                                          <Index.Typography className='team-member-position'>Head Of Marketing</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="team-box">
                                          <Index.Box className="team-img-box">
                                                <img src={PagesIndex.Svg.team2} alt="team" className='team-img' />
                                          </Index.Box>
                                          <Index.Typography className='team-member-name'>Emily Johnson</Index.Typography>
                                          <Index.Typography className='team-member-position'>Head Of Marketing</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="team-box">
                                          <Index.Box className="team-img-box">
                                                <img src={PagesIndex.Svg.team3} alt="team" className='team-img' />
                                          </Index.Box>
                                          <Index.Typography className='team-member-name'>John Snow</Index.Typography>
                                          <Index.Typography className='team-member-position'>Head Of Marketing</Index.Typography>
                                    </Index.Box>
                                    <Index.Box className="team-box">
                                          <Index.Box className="team-img-box">
                                                <img src={PagesIndex.Svg.team4} alt="team" className='team-img' />
                                          </Index.Box>
                                          <Index.Typography className='team-member-name'>Erik Jason</Index.Typography>
                                          <Index.Typography className='team-member-position'>Head Of Marketing</Index.Typography>
                                    </Index.Box>
                              </Index.Box>
                        </Index.Box>
                  </Index.Box>

            </>
      )
}
