import React from 'react'
import Index from '../../../../container/Index'
import PagesIndex from '../../../../container/PagesIndex'

export default function HeroSection() {
  return (
    <>
      <Index.Box className="hero-section">
        <Index.Box className='container'>
          <Index.Box className="hero-content-main">
            <Index.Box className="hero-content-wrapper">
              <Index.Typography className='hero-heading'>Trade Your Way to the Top
              </Index.Typography>
            </Index.Box>
            <Index.Typography className="hero-title"><span className='hero-title-span'>Aurum</span> Experience Seamless, Decentralized Trading with Speed, Security, and Simplicity.</Index.Typography>
            <Index.Box className="hero-token-wrapper-main">
              <Index.Box className="hero-token-wrapper">
                <Index.Typography className='hero-token-text'>Token Address</Index.Typography>
              </Index.Box>
            </Index.Box>
            <Index.Box className="hero-copy-btn-wrapper">
              <Index.Typography className="hero-copy-text">HhJpBhRRng56VsyLuTDL5BvHkXqsrahT</Index.Typography>
              <Index.Button className="hero-copy-btn"
                onMouseLeave={() => {
                  setTimeout(() => {
                    // setMessage("Copy");
                  }, 1000);
                }}
                onClick={() => {
                  // setMessage("Copied ✓");
                  navigator.clipboard.writeText("HhJpBhRRng56VsyLuTDL5BvHkXqsrahT");
                }}>
                <img src={PagesIndex.Svg.gradiantCopyIcon} alt="copy" className='gradiant-copy-icon' />
              </Index.Button>
            </Index.Box>
          </Index.Box>
        </Index.Box>
        <Index.Box className="hero-coin-img-wrapper">
          <img src={PagesIndex.Svg.heroCoin} alt="coin" className='hero-coin-img' />
        </Index.Box>
      </Index.Box>
    </>
  )
}
