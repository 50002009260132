import whitePaperPdf from './images/pdf/aurum-whitepaper.pdf';








const Pdf = {
      whitePaperPdf,

}

export default Pdf;