import * as Yup from "yup";

// const Joi = require("joi");
const emailRegex =
  /\b^[a-zA-Z0-9.]+@[A-Za-z0-9-]+\.[A-Za-z]{2,3}(?:\.[A-Za-z]{2,3})?\b$/;
const fullNameRegex = /^(?=[a-zA-Z])[a-zA-Z]+([',.\- ][a-zA-Z]?[a-zA-Z]*)*$/;

const valueValidationError = (fieldName, str) => {
  return `${fieldName} not allow ${str}`;
};


// const validateAdmin = Joi.object({
//   Email: Joi.string().max(50).email().required(),
//   PassWord: Joi.string().min(8).required(),
// });

export const validateUser = Yup.object({
  name: Yup.string()
    .required("Please enter name")
    .min(3, 'Please enter a valid name')
    .max(50, 'Name must be less than 50 characters long')
    .matches(
      /^(?!\s)(.*\S)?(?<!\s)$/,
      valueValidationError("Name", "spaces")
    )
    .matches(
      /^(?:(?!\s{2,}).)+$/,
      valueValidationError("Name", "more than one space")
    )
    .matches(
      fullNameRegex,
      valueValidationError("Name", "numbers and special characters")
    ),
  email: Yup.string()
    .test(
      "no-starting-dot",
      "Please enter a valid email",
      (value) => {
        if (!value) return false;
        return !/^\./.test(value);
      }
    )
    .test(
      "no-ending-dot",
      "Please enter a valid email",
      (value) => {
        if (!value) return false;
        const username = value.split('@')[0];
        return !/\.$/.test(username);
      }
    )
    .test(
      "consecutive-dots",
      "Please enter a valid email",
      (value) => !/\.{2,}/.test(value)
    )
    .test(
      "min-username-length",
      "Please enter a valid email",
      (value) => {
        if (!value) return false;
        const username = value.split('@')[0];
        return username.length >= 6;
      }
    )
    .matches(emailRegex, "Please enter valid email")
    .required("Please enter email"),
});

