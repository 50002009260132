import logo from './images/svg/logo.svg';
import gradiantCopyIcon from './images/svg/gradiant-copy.svg';
import heroCoin from './images/svg/hero-coin.svg';
import team1 from './images/svg/team1.svg';
import team2 from './images/svg/team2.svg';
import team3 from './images/svg/team3.svg';
import team4 from './images/svg/team4.svg';
import twitterIcon from './images/svg/twitter.svg';
import LinkdinIcon from './images/svg/linkdin.svg';
import youtubeIcon from './images/svg/youtube.svg';
import prevArrowIcon from './images/svg/prev-arrow.svg';
import nextArrowIcon from './images/svg/next-arrow.svg';
import faqCoin from './images/svg/faq-coin.svg';
import topArrow from './images/svg/top-arrow.svg';
import mobilemenuIcon from './images/svg/bergur-menu.svg';
import closeIcon from './images/svg/close.svg';
import footerTokenImg from './images/svg/footer-token-img.svg';





const Svg = {
    logo,
    gradiantCopyIcon,
    heroCoin,
    team1,
    team2,
    team3,
    team4,
    youtubeIcon,
    twitterIcon,
    LinkdinIcon,
    prevArrowIcon,
    nextArrowIcon,
    faqCoin,
    topArrow,
    mobilemenuIcon,
    closeIcon,
    footerTokenImg,
}

export default Svg;