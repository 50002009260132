import React, { useState } from 'react'
import Index from '../../../../container/Index'
import PagesIndex from '../../../../container/PagesIndex'

export default function Roadmap() {

      const [slider, setslider] = useState({
            slider: {
                  0: {
                        items: 1,
                        stagePadding:0,
                  },
                  550: {
                        items: 1,
                  },
                  768: {
                        items: 2,
                  },
                  1024: {
                        items: 3,
                  },
                  1200: {
                        items: 3,
                  },
                  1400: {
                        items: 3,
                  }
            }
      })



      return (
            <>
                  <Index.Box className="roadmap-sec">
                        <Index.Box className="container">
                              <Index.Typography className='roadmap-heading'>Our Roadmap</Index.Typography>
                              <Index.Typography className='roadmap-sub-title'>Aurum adoption has skyrocketed, laying the groundwork for our platform's future.
                              </Index.Typography>
                        </Index.Box>
                        <Index.Box className="roadmap-slider-main">
                              <Index.OwlCarousel
                                    className="owl-theme roadmap-slider"
                                    margin={30}
                                    responsive={slider.slider}
                                    nav={true}
                                    dots={false}
                                    center={true}
                                    autoplay={true}
                                    stagePadding={150} 
                                    autoplayTimeout={6000}
                                    startPosition={1}
                                    navText={[
                                          "<img src=" + PagesIndex.Svg.prevArrowIcon + " >",
                                          "<img src=" + PagesIndex.Svg.nextArrowIcon + " >"
                                    ]}
                              >
                                    <div class="item">
                                          <Index.Box className="roadmap-box">
                                                <Index.Box className="roadmap-dot-main">
                                                      <Index.Box className="roadmap-dot"></Index.Box>
                                                      <Index.Typography className='roadmap-year-text'>End of Q1 2024</Index.Typography>
                                                      <Index.Typography className="roadmap-content-title">Foundation Phase</Index.Typography>
                                                </Index.Box>
                                                {/* <Index.Typography className='roadmap-content-para'>
                                                      SubQuery Builders/Grants Program SQT
                                                      Network contract internal MVP Coordinator and client SDK implementations
                                                </Index.Typography> */}
                                                <ul className="roadmap-list">
                                                      <li className="roadmap-list-item">
                                                      Token Creation
                                                      </li>
                                                      <li className="roadmap-list-item">
                                                      Token Minting
                                                      </li>
                                                      <li className="roadmap-list-item">
                                                      White Paper 
                                                      </li>
                                                      <li className="roadmap-list-item">
                                                      Web Declaration
                                                      </li>
                                                </ul>
                                          </Index.Box>
                                    </div>
                                    <div class="item">
                                          <Index.Box className="roadmap-box">
                                                <Index.Box className="roadmap-dot-main">
                                                      <Index.Box className="roadmap-dot"></Index.Box>
                                                      <Index.Typography className='roadmap-year-text'>End of Q2 2024
                                                      </Index.Typography>
                                                      <Index.Typography className="roadmap-content-title">Structural Development
                                                      </Index.Typography>
                                                </Index.Box>
                                                {/* <Index.Typography className='roadmap-content-para'>
                                                      SubQuery Builders/Grants Program SQT
                                                      Network contract internal MVP Coordinator and client SDK implementations
                                                </Index.Typography> */}
                                                <ul className="roadmap-list">
                                                      <li className="roadmap-list-item">
                                                      Supply Minting
                                                      </li>
                                                      <li className="roadmap-list-item">
                                                      Tokenomics Design
                                                      </li>
                                                      <li className="roadmap-list-item">
                                                      LP Setup and Registration with Swaps
                                                      </li>
                                                </ul>
                                          </Index.Box>
                                    </div>
                                    <div class="item">
                                          <Index.Box className="roadmap-box">
                                                <Index.Box className="roadmap-dot-main">
                                                      <Index.Box className="roadmap-dot"></Index.Box>
                                                      <Index.Typography className='roadmap-year-text'>End of Q3 2024
                                                      </Index.Typography>
                                                      <Index.Typography className="roadmap-content-title">Initial Launch
                                                      </Index.Typography>
                                                </Index.Box>
                                                {/* <Index.Typography className='roadmap-content-para'>
                                                      SubQuery Builders/Grants Program SQT
                                                      Network contract internal MVP Coordinator and client SDK implementations
                                                </Index.Typography> */}
                                                <ul className="roadmap-list">
                                                      <li className="roadmap-list-item">
                                                      Enabling Primary Swaps
                                                      </li>
                                                      <li className="roadmap-list-item">
                                                      Enabling Round One Supply According to Tokenomics and LP
                                                      </li>
                                                </ul>
                                          </Index.Box>
                                    </div>
                                    <div class="item">
                                          <Index.Box className="roadmap-box">
                                                <Index.Box className="roadmap-dot-main">
                                                      <Index.Box className="roadmap-dot"></Index.Box>
                                                      <Index.Typography className='roadmap-year-text'>End of Q4 2024</Index.Typography>
                                                      <Index.Typography className="roadmap-content-title">Market Introduction
                                                      </Index.Typography>
                                                </Index.Box>
                                                {/* <Index.Typography className='roadmap-content-para'>
                                                      SubQuery Builders/Grants Program SQT
                                                      Network contract internal MVP Coordinator and client SDK implementations
                                                </Index.Typography> */}
                                                <ul className="roadmap-list">
                                                      <li className="roadmap-list-item">
                                                      Listing on CMC
                                                      </li>
                                                      <li className="roadmap-list-item">
                                                      Listing on Initial DEX's
                                                      </li>
                                                </ul>
                                          </Index.Box>
                                    </div>
                                    <div class="item">
                                          <Index.Box className="roadmap-box">
                                                <Index.Box className="roadmap-dot-main">
                                                      <Index.Box className="roadmap-dot"></Index.Box>
                                                      <Index.Typography className='roadmap-year-text'>End of Q5 2024
                                                      </Index.Typography>
                                                      <Index.Typography className="roadmap-content-title">Expansion Phase
                                                      </Index.Typography>
                                                </Index.Box>
                                                {/* <Index.Typography className='roadmap-content-para'>
                                                      SubQuery Builders/Grants Program SQT
                                                      Network contract internal MVP Coordinator and client SDK implementations
                                                </Index.Typography> */}
                                                <ul className="roadmap-list">
                                                      <li className="roadmap-list-item">
                                                      Listing with Mid-Level Centralized Exchanges
                                                      </li>
                                                </ul>
                                          </Index.Box>
                                    </div>
                                    <div class="item">
                                          <Index.Box className="roadmap-box">
                                                <Index.Box className="roadmap-dot-main">
                                                      <Index.Box className="roadmap-dot"></Index.Box>
                                                      <Index.Typography className='roadmap-year-text'>End of Q6 2024
                                                      </Index.Typography>
                                                      <Index.Typography className="roadmap-content-title">Major Exchange Integration
                                                      </Index.Typography>
                                                </Index.Box>
                                                {/* <Index.Typography className='roadmap-content-para'>
                                                      SubQuery Builders/Grants Program SQT
                                                      Network contract internal MVP Coordinator and client SDK implementations
                                                </Index.Typography> */}
                                                <ul className="roadmap-list">
                                                      <li className="roadmap-list-item">
                                                      Listing on Major Exchanges    
                                                      </li>
                                                </ul>
                                          </Index.Box>
                                    </div>
                              </Index.OwlCarousel>
                        </Index.Box>
                  </Index.Box>
            </>
      )
}
