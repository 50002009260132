import coming from './images/gif/soon.gif';
import comingsoon from './images/gif/coming-soon.gif';








const Gif = {
      coming,
      comingsoon,
  
  }
  
  export default Gif;